import React from "react";
import { Link, navigate } from "gatsby"
import Axios from "axios"
import { setItem, isLoggedIn } from "../../services/auth"
import queryString from 'query-string';

const qs = require("query-string")

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};
class RegisterPage extends React.Component { 
  constructor(props) {
    super(props);
    const params = queryString.parse(this.props.location.search);
    this.state = {
      token: params.token,
      email: "",
      password: "",
      password_confirmation: ""
    };

    this.onInputchange = this.onInputchange.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  
  
    // console.log("state on init 1", this.state);
    // this.getUrlParam = this.getUrlParam.bind(this);
    // this.onPop = this.onLoginSocial.bind(this);
  }

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onSubmitForm() {
    console.log(this.state)
  }

  onLoginSocial = (e, loginType) => {
    //lambda url
    var url = `/.netlify/functions/auth/${loginType}`;
    var win = typeof window !== `undefined` ? window : null;
    var n = win.open(url, "_self");
    if (n == null) {
      return true;
    }
    return false;
  };

  onResetPassword = (e) => {
    e.preventDefault();
    console.log(e);
    const data = {
      token: e.target.token.value,
      email: e.target.email.value,
      password: e.target.password.value,
      password_confirmation: e.target.password_confirmation.value
    };
    console.log("data", data);
    
    Axios.post('https://api.gogoblock.io/api/reset-password', data)
        .then((result) => {
          navigate(`/auth/password-changed`)
        }).catch((err) => {
            console.error(err);
        });
  };

  getInitialState() {
    return {
      inputValue: this.props.defaultInputValue
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultInputValue !== this.props.inputValue) {
      //Forcibly overwrite input value to new default if the default ever changes
      this.setState({inputValue: nextProps.defaultInputValue});
    }
  }
  render() {
    return (
      <>
        {/*
          This example requires updating your template:
  
          ```
          <html className="h-full bg-gray-50">
          <body className="h-full">
          ```
        */}
        <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Reset Password</h2>
          </div>
  
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form 
                className="space-y-6" 
                method="POST"
                onSubmit={e => this.onResetPassword(e)}
                >
                <input type="hidden" name="token" id="token" value={this.state.token}/>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={this.state.email}
                      onChange={this.onInputchange}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
  
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      value={this.state.password}
                      onChange={this.onInputchange}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password Confirmation
                  </label>
                  <div className="mt-1">
                    <input
                      id="password_confirmation"
                      name="password_confirmation"
                      type="password"
                      value={this.state.password_confirmation}
                      onChange={this.onInputchange}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Reset
                  </button>
                </div>
              </form>
  
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default RegisterPage;